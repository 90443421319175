import { API_ROOT_URL } from './hostname'

class GraphQL {
  authenticate = credentials => {
    return this.post(`${API_ROOT_URL}/authenticate`, credentials).then(d => d.data)
  }

  query = async (request, payload) => {
    return this.post(`${API_ROOT_URL}/graphql`, {
      query: request,
      variables: payload,
    }).then(r => r.data)
  }

  get = url => {
    return this.request(url, 'GET')
  }

  post = (url, payload) => {
    return this.request(url, 'POST', payload)
  }

  checkAuthError = response => {
    if (response.status === 401)
      return store.dispatch({ type: AUTHENTICATE_ERROR })
    return response
  }

  insertTokenIntoHeaders = headers => {
    const token = localStorage.getItem('token')

    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    if (token) {
      headers.append('Authorization', token)
    }
  }

  fetchWithTimeout = (url, params, timeout) =>
    new Promise((resolve, reject) => {
      const errorTimeout = setTimeout(
        reject.bind(null, new Error('Timeout')),
        timeout,
      )
      fetch(`${url}`, params)
        .then(res => {
          clearTimeout(errorTimeout)
          resolve(res)
        })
        .catch(e => reject(e))
    })

  request = async (url, method, payload) => {
    const headers = new Headers()
    this.insertTokenIntoHeaders(headers)

    const params = {
      method,
      headers,
      body: JSON.stringify(payload),
    }

    try {
      const response = await this.fetchWithTimeout(`${url}`, params, 10000)

      this.checkAuthError(response)

      const jsonResponse = await response.json()

      if (jsonResponse.errors) {
        throw new Error(jsonResponse.errors[0].message)
      }

      return jsonResponse
    } catch (e) {
      throw e
    }
  }
}

export default new GraphQL()
