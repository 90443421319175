import ApplicationRoutes from '../../routes'
import BookmarkIcon from '@material-ui/icons/BookmarkBorderOutlined'
import BookmarkIconChecked from '@material-ui/icons/BookmarkOutlined'
import CheckIcon from '@material-ui/icons/Check'
import CommentIcon from '@material-ui/icons/ChatBubbleOutline'
import ConfirmDialog from '../../components/confirm'
import FlagOutlined from '@material-ui/icons/FlagOutlined'
import Formatter from '../../utils/formatter'
import HistoryIcon from '@material-ui/icons/HistoryOutlined'
import { flagLabels } from './flag'
import { Fragment } from 'preact'
import { timeAgo, toDateFormat } from '../../utils/date'
import { useState } from 'preact/hooks'
import {
  TableRow,
  TableCell,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Link,
  Badge,
} from '@material-ui/core'

const ExpirationProductItem = ({
  product,
  onUpdate,
  toggleComments,
  toggleHistory,
  toggleBookmark,
  bookmarked,
}) => {
  let [dialog, setDialog] = useState(false)
  const border =
    (product.projected && product.projected < product.amount) ||
    product.projected === 0
      ? { borderLeft: '8px solid red' }
      : {}

  return (
    <Fragment>
      <ConfirmDialog
        open={dialog}
        title='Finalizar'
        content='Realmente deseja finalizar este produto?'
        cancelLabel='Cancelar'
        confirmLabel='Finalizar'
        onCancel={() => setDialog(null)}
        onConfirm={() => {
          setDialog(null)
          onUpdate(product)
        }}
      />
      <TableRow>
        <TableCell style={border}>
          <Grid container direction='column'>
            <Grid item>
              <Typography variant='body2'>{product.store}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='caption'>
                {product.departmentDescription}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography variant='h6'>{product.amount}</Typography>
        </TableCell>
        <TableCell>
          {product.suggestedPrice ? (
            <Fragment>
              <Grid item>
                <Typography variant='h6'>
                  {Formatter.currency(product.suggestedPrice, 2)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: 'rgba(0,0,0, 0.47)',
                  }}
                  variant='caption'
                >
                  {product.suggestedPrice
                    ? `${Formatter.currency(
                        product.price,
                        2,
                      )} / ${Formatter.currency(product.averageCost, 2)}`
                    : '-'}
                </Typography>
              </Grid>
            </Fragment>
          ) : (
            <Grid item>
              <Typography
                style={{
                  color: 'rgba(0,0,0, 0.47)',
                }}
                variant='caption'
              >
                N/D
              </Typography>
            </Grid>
          )}
        </TableCell>
        <TableCell>
          <Grid container direction='column'>
            <Grid item>
              <Link
                href={ApplicationRoutes.ExpirationEdit.replace(
                  ':key',
                  product._key,
                )}
              >
                <strong>{product.description}</strong>
              </Link>
            </Grid>

            <Grid item>
              {product.complement && (
                <Typography variant='caption'>{product.complement}</Typography>
              )}
              {product.brand && (
                <Typography variant='caption'> - {product.brand}</Typography>
              )}
            </Grid>
            <Grid item>
              <Typography variant='caption'>
                {product.barcode} - {product.code}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid container direction='column'>
            <Grid item>
              {toDateFormat(product.expiration, 'dd/mm/yy', 'yyyy-mm-dd')}
            </Grid>
            <Grid item>
              <Typography variant='caption'>
                {timeAgo(product.expiration)}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <IconButton>
            <Tooltip title={flagLabels[product.flag]}>
              {product.flag ? (
                <img
                  style={{ margin: 2 }}
                  height={20}
                  width={14}
                  src={`/assets/images/flag_${product.flag}.png`}
                />
              ) : (
                <FlagOutlined />
              )}
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={() => toggleBookmark(`${product._id};${product.code}`)}
          >
            <Tooltip title='Marcar para Exportação'>
              {bookmarked ? <BookmarkIconChecked /> : <BookmarkIcon />}
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => toggleComments(product._id)}>
            <Tooltip title='Comentários'>
              <Badge badgeContent={product.numComments} color='primary'>
                <CommentIcon />
              </Badge>
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => toggleHistory(product._id)}>
            <Tooltip title='Histórico'>
              <HistoryIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => setDialog(true)}>
            <Tooltip title='Finalizar'>
              <CheckIcon />
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default ExpirationProductItem
