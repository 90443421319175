import ApplicationRoutes from '../routes'
import { action, observable } from 'mobx'
import { route } from 'preact-router'

class AuthStore {
  @observable user
  @observable token

  @action
  setCredentials = ({ user, token }) => {
    this.user = user
    this.token = token
  }

  @action
  logout = () => {
    this.user = undefined
    this.token = undefined
    route(ApplicationRoutes.Login)
  }
}

export default AuthStore
