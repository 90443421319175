import graphql from '../../http/graphql'
import { expirationStatsQuery, expirationEventStatsQuery } from './queries'

class ReportService {
  stats = filter => {
    return graphql
      .query(expirationStatsQuery, { filter })
      .then(d => d.expirationStats)
  }

  eventStats = filter => {
    return graphql
      .query(expirationEventStatsQuery, { filter })
      .then(d => d.expirationEventStats)
  }
}

export const reportService = new ReportService()
