import ApplicationRoutes from './routes'
import { inject, observer } from 'mobx-preact'
import { route } from 'preact-router'
import { useEffect } from 'preact/hooks'

const Redirect = props => {
  useEffect(() => {
    route(props.to)
  }, [props.to])

  return null
}

function ProtectedRoute({ component: Component, auth, ...rest }) {
  const token = localStorage.getItem('token')
  let user

  if (!token) {
    return <Redirect to={ApplicationRoutes.Login} />
  }

  const storedUser = localStorage.getItem('user')
  user = JSON.parse(storedUser)

  if (token) {
    auth.setCredentials({ token, user })
  }

  const script = document.createElement('script')
  script.innerHTML = `
    window.intercomSettings = {
      app_id: "aryp9okv",
      name: "${user.name}",
      email: "${user.email}",
      custom_launcher_selector:'#help-icon',
      hide_default_launcher: true
    };
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/aryp9okv';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  `
  document.body.appendChild(script)

  return <Component {...rest} />
}

export default inject('auth')(observer(ProtectedRoute))
