import { Grid, Typography } from '@material-ui/core'
import { toDateFormat } from '../../utils/date'

const ListDate = ({ date }) => (
  <Grid container alignSelf='center' justify='center' direction='column'>
    <Grid item>
      <Typography style='margin-top: 12px' align='center' variant='h6'>
        <strong>{toDateFormat(date, 'dd/MM')}</strong>
      </Typography>
    </Grid>
    <Grid item>
      <Typography align='center' variant='body1'>
        {toDateFormat(date, 'HH:mm')}
      </Typography>
    </Grid>
  </Grid>
)

export default ListDate
