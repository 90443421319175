import { Container as MaterialContainer, Grid } from '@material-ui/core'
import { Component, Fragment } from 'preact'
import Spacer from '../../components/spacer'
import { reportService } from './service'
import expired from './expired.json'
import prevented from './prevented.json'
import departments from './departments.json'
import funnelStats from './stats.json'
import {
  Bar,
  BarChart,
  PieChart,
  Pie,
  Tooltip,
  FunnelChart,
  Funnel,
  LabelList,
  Label,
  Cell,
} from 'recharts'
import DatePicker from '../../components/date/picker'
import { ExpirationStatusTable } from './status'
import { ReportCard } from '../common/report_card'
import { EventsStatusTable } from './events'
import { SectionTitle } from '../common/section_title'
import { ReportDepartmentsTable } from './departments'

const colors = ['#8884d8', '#83A6ED', '#8DD1E1', '#82CA9D', '#A4DE6C']

export class ExpirationReportsScreen extends Component {
  state = {
    active: true,
    stats: undefined,
    eventStats: undefined,
  }

  componentDidMount = () => {
    this.fetchStats()
  }

  fetchStats = async () => {
    try {
      const results = await Promise.all([
        reportService.stats({ store: '032' }),
        reportService.eventStats({
          store: '032',
          start: '2020-06-01',
          end: '2020-08-13',
        }),
      ])

      this.setState({ stats: results[0], eventStats: results[1] })
    } catch (e) {
      console.log(e)
    }
  }

  render () {
    const { stats, eventStats } = this.state
    console.log(eventStats)
    return (
      <Fragment>
        <Spacer double />
        <MaterialContainer maxWidth='lg'>
          <DatePicker
            onSelect={interval => {
              this.setState({ interval })
            }}
          />
          <Spacer double />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <ReportCard label='Risco de perdas' value='R$ 24.600' />
            </Grid>
            <Grid item xs={4}>
              <ReportCard label='Tempo de Validação' value='28,3 horas' />
            </Grid>
            <Grid item xs={4}>
              <ReportCard label='Tempo Análise' value='46,8 horas' />
            </Grid>
          </Grid>
          <Spacer double />
          <Grid container spacing={3} justify='center' alignItems='center'>
            <Grid item xs={4}>
              <FunnelChart width={400} height={200}>
                <Tooltip />
                <Funnel dataKey='value' data={funnelStats} isAnimationActive>
                  <LabelList
                    position='center'
                    fill='#fff'
                    stroke='none'
                    dataKey='name'
                  />
                  <Label />
                </Funnel>
              </FunnelChart>
            </Grid>
            <Grid item xs={4}>
              <ReportCard label='Resolvidos' value='R$ 12.455'>
                <BarChart width={200} height={100} data={prevented}>
                  <Bar dataKey='total' fill='#8884d8' />
                </BarChart>
              </ReportCard>
            </Grid>
            <Grid item xs={4}>
              <ReportCard label='Vencidos' value='R$ 322'>
                <BarChart width={200} height={100} data={expired}>
                  <Bar dataKey='total' fill='#8884d8' />
                </BarChart>
              </ReportCard>
            </Grid>
          </Grid>
          <Spacer size={36} />
          <SectionTitle title='Status Atual' />
          <Spacer size={36} />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {stats && (
                <PieChart width={300} height={300}>
                  <Pie data={stats} dataKey='products' nameKey='display'>
                    {stats.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              )}
            </Grid>
            <Grid item xs={9}>
              <ExpirationStatusTable data={stats} />
            </Grid>
          </Grid>
          <SectionTitle title='Eventos' />
          <Spacer size={36} />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <PieChart width={300} height={300}>
                {eventStats && (
                  <Pie data={eventStats} dataKey='products' nameKey='display'>
                    {eventStats.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  </Pie>
                )}
                <Tooltip />
              </PieChart>
            </Grid>
            <Grid item xs={9}>
              <EventsStatusTable data={eventStats} />
            </Grid>
          </Grid>
          <Spacer size={36} />
          <SectionTitle title='Departamentos' />
          <Spacer size={36} />
          <ReportDepartmentsTable data={departments} />
        </MaterialContainer>
      </Fragment>
    )
  }
}
