import AppBar from './components/appbar'
import ApplicationRoutes from './routes'
import Drawer from './drawer'
import ExpirationBookmarks from './expiration/bookmark/screen'
import ExpirationEditScreen from './expiration/edit/screen'
import ExpirationFinishedScreen from './expiration/finished/screen'
import ExpirationHistory from './expiration/history/screen'
import ExpirationScreen from './expiration/products/screen'
import { ExpirationReportsScreen } from './expiration/reports/screen'
import Router, { route } from 'preact-router'
import SnackbarController from './components/snackbar'
import { Component, Fragment } from 'preact'

class App extends Component {
  componentDidMount () {
    if (this.props.url === '/') {
      route(ApplicationRoutes.Expiration)
    }
  }

  render () {
    return (
      <Fragment>
        <AppBar />
        <Drawer />
        <Router>
          <ExpirationScreen path={ApplicationRoutes.Expiration} />
          <ExpirationEditScreen path={ApplicationRoutes.ExpirationEdit} />
          <ExpirationHistory path={ApplicationRoutes.ExpirationHistory} />
          <ExpirationReportsScreen path={ApplicationRoutes.ExpirationReports} />
          <ExpirationBookmarks path={ApplicationRoutes.ExpirationBookmarks} />
          <ExpirationFinishedScreen
            path={ApplicationRoutes.ExpirationFinished}
          />
        </Router>
        <SnackbarController />
      </Fragment>
    )
  }
}

export default App
