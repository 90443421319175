import CommentsPanel from '../common/comments'
import ExpirationProductItem from '../common/item'
import HistoryPanel from './history'
import { inject, observer } from 'mobx-preact'
import { useState } from 'preact/hooks'
import {
  Grid,
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core'

function ProductList({ products, onUpdate, expiration }) {
  const [historyOpened, setHistoryOpened] = useState(false)
  const [commentsOpened, setCommentsOpened] = useState(false)
  const [activeProduct, setActiveProduct] = useState(null)

  const handleToggleBookmark = id => expiration.updateBookmarked(id)

  return (
    <Grid container>
      <HistoryPanel
        id={activeProduct}
        opened={historyOpened}
        onClose={() => setHistoryOpened(false)}
      />
      <CommentsPanel
        id={activeProduct}
        opened={commentsOpened}
        onClose={() => setCommentsOpened(false)}
      />
      {products && products.length === 0 && (
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: 24, textAlign: 'center' }}>
            <Typography>Nenhum produto encontrado</Typography>
          </Paper>
        </Grid>
      )}
      {products && products.length > 0 && (
        <TableContainer elevation={0} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Localização</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Sugestão</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {products &&
                products.map(p => (
                  <ExpirationProductItem
                    key={p._id}
                    toggleBookmark={handleToggleBookmark}
                    toggleComments={activeProduct => {
                      setActiveProduct(activeProduct)
                      setCommentsOpened(true)
                    }}
                    toggleHistory={activeProduct => {
                      setActiveProduct(activeProduct)
                      setHistoryOpened(true)
                    }}
                    onUpdate={onUpdate}
                    product={p}
                    bookmarked={
                      expiration.bookmarkIds &&
                      expiration.bookmarkIds.includes(p._id)
                    }
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  )
}

export default inject('expiration')(observer(ProductList))
