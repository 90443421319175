const ApplicationRoutes = {
  Login: '/login',
  Home: '/',
  Expiration: '/validade',
  ExpirationReports: '/validade/relatorios',
  ExpirationEdit: '/validade/editar/:key',
  ExpirationHistory: '/validade/historico',
  ExpirationBookmarks: '/validade/favoritos',
  ExpirationFinished: '/validade/finalizados',
}

export default ApplicationRoutes
