import ApplicationRoutes from '../../routes'
import ArrowBack from '@material-ui/icons/ArrowBackOutlined'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import ConfirmDialog from '../../components/confirm/index'
import Container from '../../components/container'
import DownloadIcon from '@material-ui/icons/GetAppOutlined'
import expirationService from '../service'
import ProductList from '../common/products'
import Spacer from '../../components/spacer/index'
import { Component } from 'preact'
import { inject, observer } from 'mobx-preact'
import { route } from 'preact-router'
import {
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core'

class ExpirationBookmarks extends Component {
  state = {
    bookmarks: undefined,
    confirm: false,
  }

  componentDidMount() {
    this.fetchProducts()
  }

  downloadProducts = async () => {
    const { layout, expiration } = this.props

    if (expiration.bookmarkIds.length === 0) {
      return layout.showSnackbar(true, 'Nenhum produto para alterar preço')
    }

    try {
      const data = await expirationService.download(expiration.bookmarkIds)
      const date = new Date().toISOString().substring(0, 10)

      const element = document.createElement('a')
      const file = new Blob(data, { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = `alterao-precos-${date}.txt`
      element.click()
    } catch (e) {
      console.log(e)
    }
  }

  async fetchProducts() {
    const { expiration } = this.props
    try {
      if (expiration.bookmarkIds) {
        const products = await expirationService.productsByIds(
          expiration.bookmarkIds,
        )
        this.setState({ products })
      }
    } catch (e) {
      console.log(e)
    }
  }

  verifyProducts = async () => {
    const { products } = this.state
    const { layout, expiration } = this.props

    if (products.length === 0) {
      return layout.showSnackbar(true, 'Nenhum produto para alterar status')
    }

    try {
      if (expiration.bookmarkIds) {
        await expirationService.verifyProducts(expiration.bookmarkIds)
        layout.showSnackbar(
          false,
          'Atualizado status dos produtos para Verificar',
        )
        expiration.clearBookmarks()
        history.go(-1)
      }
    } catch (e) {
      console.log(e)
    }
  }

  render({ classes }) {
    const { products, confirm } = this.state
    return (
      <Container>
        <ConfirmDialog
          open={confirm}
          title='Alterar status'
          content='Deseja alterar o status dos produtos para Verificar? Não é possível desfazer esta operação.'
          cancelLabel='Cancelar'
          confirmLabel='Alterar'
          onCancel={() => this.setState({ confirm: false })}
          onConfirm={() => {
            this.verifyProducts()
            this.setState({ confirm: false })
          }}
        />
        <Grid style={{ marginTop: 24 }} container direction='column'>
          <Grid item>
            <Toolbar>
              <IconButton
                onClick={() => route(ApplicationRoutes.Expiration)}
                edge='start'
                color='inherit'
              >
                <ArrowBack />
              </IconButton>
              <div style={{ flex: 1 }} />
              <Typography variant='h6'>Produtos para Exportação</Typography>
              <div style={{ flex: 1 }} />
              <IconButton
                onClick={this.downloadProducts}
                edge='start'
                color='inherit'
              >
                <DownloadIcon />
              </IconButton>
              <Spacer />
              <IconButton
                onClick={() => this.setState({ confirm: true })}
                edge='start'
                color='inherit'
              >
                <CheckIcon />
              </IconButton>
            </Toolbar>
          </Grid>
          <Spacer double />
          {products && products.length === 0 && (
            <Grid item>
              <Paper elevation={0} className={classes.emptyMessage}>
                <Typography>Nenhum produto nos favoritos</Typography>
              </Paper>
            </Grid>
          )}
          {products && products.length > 0 && (
            <ProductList
              products={products}
              onUpdate={async product => {
                try {
                  product.status = 'verify'
                  await expirationService.update(product)
                  this.props.layout.showSnackbar(
                    false,
                    'Produto atualizado com sucesso',
                  )
                  this.fetchProducts()
                } catch (e) {
                  this.props.layout.showSnackbar(
                    true,
                    'Erro ao atualizar o produto',
                  )
                }
              }}
            />
          )}
        </Grid>
      </Container>
    )
  }
}

const styles = () => ({
  emptyMessage: {
    padding: 24,
    textAlign: 'center',
  },
})

const ExpirationBookmarksScreen = inject(
  'expiration',
  'layout',
)(observer(ExpirationBookmarks))
export default withStyles(styles)(ExpirationBookmarksScreen)
