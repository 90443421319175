import graphql from '../http/graphql'
import {
  addExpirationCommentMutation,
  updateExpirationMutation,
  verifyExpirationProductsMutation,
  calculateExpirationPriceSuggestionsMutation,
} from './graphql/mutations'
import {
  expirationOverviewQuery,
  expirationProducts,
  expirationProductQuery,
  expirationProductComments,
  expirationProductHistory,
  finishedBeforeExpirationQuery,
  expirationDownloadProductsMutation,
  expirationProductsByIdQuery,
} from './graphql/queries'

class ExpirationService {
  overview = () => {
    return graphql.query(expirationOverviewQuery)
  }

  product = key =>
    graphql
      .query(expirationProductQuery, { id: `expiration/${key}` })
      .then(d => d.expirationProduct)

  products = async filter =>
    graphql
      .query(expirationProducts, { filter })
      .then(d => d.expirationProducts)

  productsByIds = async ids =>
    graphql
      .query(expirationProductsByIdQuery, { ids })
      .then(d => d.expirationProductsById)

  comments = async id =>
    graphql
      .query(expirationProductComments, { id })
      .then(d => d.expirationComments)

  history = async id =>
    graphql
      .query(expirationProductHistory, { id })
      .then(d => d.expirationHistory)

  addComment = async (id, data) =>
    graphql
      .query(addExpirationCommentMutation, { id, data })
      .then(d => d.addExpirationComment)

  download = (ids, exp) =>
    graphql
      .query(expirationDownloadProductsMutation, { ids, exp })
      .then(d => d.downloadExpirationProducts)

  verifyProducts = ids =>
    graphql
      .query(verifyExpirationProductsMutation, { ids })
      .then(d => d.verifyExpirationProducts)

  finishedBeforeExpiration = () =>
    graphql
      .query(finishedBeforeExpirationQuery)
      .then(d => d.expirationFinishedBeforeExpiration)

  calculatePriceSuggestions = ids =>
    graphql
      .query(calculateExpirationPriceSuggestionsMutation, { ids })
      .then(d => d.expirationFinishedBeforeExpiration)

  update = async data => {
    const update = {
      _id: data._id,
      _key: data._key,
      _rev: data._rev,
      status: data.status,
      amount: data.amount,
      expiration: data.expiration,
      flag: data.flag,
      suggestedPrice: data.suggestedPrice,
      secondaryBarcode: data.secondaryBarcode,
      finishedAndValidated: data.finishedAndValidated,
    }
    return graphql
      .query(updateExpirationMutation, { id: data._id, data: update })
      .then(d => d.updateExpirationProduct)
  }
}

const expirationService = new ExpirationService()
export default expirationService
