import { action, observable, computed } from 'mobx'

class ExpirationStore {
  @observable bookmarks = []
  @observable codes = []

  constructor(root) {
    this.root = root
    this.bookmarks = this.getStoredBookmarks()
  }

  @computed
  get bookmarkIds() {
    return this.bookmarks.map(e => e.split(';')[0])
  }

  @computed
  get bookmarkCodes() {
    return this.bookmarks.map(e => e.split(';')[1])
  }

  @action
  clearBookmarks = () => {
    this.bookmarks = []
    localStorage.removeItem('expiration.bookmarks')
  }

  getStoredBookmarks = () => {
    const storedBookmarks = localStorage.getItem('expiration.bookmarks')
    let bookmarks
    if (!storedBookmarks) {
      bookmarks = []
    } else {
      bookmarks = JSON.parse(storedBookmarks)
    }
    return bookmarks
  }

  @action
  updateBookmarked(product) {
    const bookmarks = this.getStoredBookmarks()

    if (bookmarks.includes(product)) {
      bookmarks.splice(bookmarks.indexOf(product), 1)
    } else {
      if (this.bookmarkCodes.includes(product.split(';')[1])) {
        return this.root.layout.showSnackbar(
          true,
          'Este código de produto já está nos favoritos',
        )
      }

      bookmarks.push(product)
    }

    localStorage.setItem('expiration.bookmarks', JSON.stringify(bookmarks))
    this.bookmarks = [...bookmarks]
  }
}

export default ExpirationStore
