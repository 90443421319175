import dateIntervals from './intervals'
import { useState } from 'preact/hooks'
import {
  ListItem,
  List,
  DialogTitle,
  Dialog,
  ListItemText,
  Grid,
  Typography,
  Card,
  CardActionArea,
} from '@material-ui/core'
import { formatDateDisplay, formatFromISO } from '../../utils/date'

export default function DatePicker ({ onSelect }) {
  const [open, setOpen] = useState(false)
  const [interval, setInterval] = useState(dateIntervals[0])

  return (
    <Grid item>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle id='simple-dialog-title'>Selecione um período</DialogTitle>
        <List>
          {dateIntervals.map(d => (
            <ListItem
              button
              selected={d === interval}
              onClick={() => {
                setOpen(false)
                setInterval(d)
                onSelect(d)
              }}
            >
              <ListItemText primary={d.label} />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Card elevation={0}>
        <CardActionArea onClick={() => setOpen(true)} style={{ padding: 16 }}>
          <Grid container direction='column'>
            <Grid item>
              <Typography variant='h5'>{interval.label}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='caption'>
                {formatFromISO(interval.start)} -{' '}
                {formatFromISO(interval.end)}
              </Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
