import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

export default function SnackbarAlert({ open, message, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={() => setTimeout(() => onClose(), 5000)}
    >
      <Alert elevation={2} variant='filled' severity='error'>
        {message}
      </Alert>
    </Snackbar>
  )
}
