import { action, observable } from 'mobx'

export default class LayoutStore {
  constructor(root) {
    this.root = root
  }

  @observable drawerOpened = false
  @observable backButton = false
  @observable title = ''
  @observable snackbarOpen = false
  @observable snackbarAlert = false
  @observable snackbarMessage = ''

  @action
  toggleDrawer = () => {
    this.drawerOpened = !this.drawerOpened
  }

  @action
  showBackButton = () => {
    this.backButton = true
  }

  @action
  hideBackButton = () => {
    this.backButton = false
  }

  @action
  setTitle = title => {
    this.title = title
  }

  @action
  showSnackbar = (alert, message) => {
    this.snackbarOpen = true
    this.snackbarAlert = alert
    this.snackbarMessage = message
  }

  @action
  hideSnackbar = () => {
    this.snackbarOpen = false
    this.snackbarAlert = false
    this.snackbarMessage = ''
  }
}
