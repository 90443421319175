import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core'
import Formatter from '../../utils/formatter'

export function EventsStatusTable ({ data }) {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Evento</TableCell>
            <TableCell># Produtos</TableCell>
            <TableCell>Valor R$</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map(d => (
              <TableRow>
                <TableCell>{d.display}</TableCell>
                <TableCell>{Formatter.number(d.products)}</TableCell>
                <TableCell>{Formatter.currency(d.amount)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
