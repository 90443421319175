import {
  Badge, Box, CircularProgress, Container as MaterialContainer, Grid, IconButton,
  Paper,
  TableContainer, Tooltip, Typography
} from '@material-ui/core'
import { BookmarkBorderOutlined, DoneAllOutlined } from '@material-ui/icons'
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyOutlined'
import FilterListOutlined from '@material-ui/icons/FilterListOutlined'
import GetAppOutlined from '@material-ui/icons/GetAppOutlined'
import { inject, observer } from 'mobx-preact'
import { Component, Fragment } from 'preact'
import { route } from 'preact-router'
import Container from '../../components/container/index'
import Spacer from '../../components/spacer'
import ApplicationRoutes from '../../routes'
import DepartmentsPanel from '../common/departments'
import FlagPicker from '../common/flag_picker'
import ProductList from '../common/products'
import expirationService from '../service'

class ExpirationProducts extends Component {
  state = {
    status: 'analysis',
    products: [],
    filtered: [],
    departments: [],
    selectedDepartments: [],
    open: false,
    activeProduct: null,
    commentsOpened: false,
    historyOpened: false,
    flag: null,
    calculating: false,
  }

  auth

  constructor(props) {
    super(props)

    this.auth = props.auth
    const flag = localStorage.getItem('expiration.flag')
    if (flag && flag != 'null') {
      this.state.flag = flag
    }
  }

  componentDidMount = () => {
    this.fetchProducts()
  }

  extractDepartments = products => {
    const departments = []
    for (const p of products) {
      if (!p.departmentDescription) {
        continue
      }

      if (departments.indexOf(p.departmentDescription) === -1) {
        departments.push(p.departmentDescription)
      }
    } 6
    return departments
  }

  fetchProducts = async () => {
    const { status, flag } = this.state
    const userStores = this.auth.user.stores || []

    try {
      const filter = { status, flag, sort: 'analysisAt' }
      let products = await expirationService.products(filter)

      if (userStores.length > 0) {
        products = products.filter(p => userStores.includes(p.store))
      }


      const departments = this.extractDepartments(products)
      const selectedDepartments = this.fetchStoredSelectedDepartments() || [
        ...departments,
      ]
      this.setState({ departments, selectedDepartments }, () =>
        this.filterProducts(products),
      )
    } catch (e) {
      console.log(e)
    }
  }

  fetchStoredSelectedDepartments = () => {
    const stored = localStorage.getItem('expiration.selectedDepartments')
    if (stored) {
      return JSON.parse(stored)
    }
    return null
  }

  filterProducts = products => {
    const { selectedDepartments } = this.state
    const filtered = products.filter(
      p =>
        p.departmentDescription === null ||
        selectedDepartments.includes(p.departmentDescription),
    )
    this.setState({
      filtered,
      products,
    })
  }

  handleTabChange = (e, status) => {
    this.setState({ status }, this.fetchProducts)
  }

  handleFlagUpdate = flagUpdate => {
    const { flag } = this.state
    if (flag === flagUpdate) {
      flagUpdate = null
    }

    if (flagUpdate != null) {
      localStorage.setItem('expiration.flag', flagUpdate)
    } else {
      localStorage.removeItem('expiration.flag')
    }

    this.setState({ flag: flagUpdate }, this.fetchProducts)
  }

  handleToggleDepartment = department => {
    const { selectedDepartments, products } = this.state
    if (selectedDepartments.includes(department)) {
      selectedDepartments.splice(selectedDepartments.indexOf(department), 1)
    } else {
      selectedDepartments.push(department)
    }

    if (selectedDepartments.length > 0) {
      localStorage.setItem(
        'expiration.selectedDepartments',
        JSON.stringify(selectedDepartments),
      )
    }

    this.setState({ selectedDepartments }, () => this.filterProducts(products))
  }

  handleCalculatePriceSuggestions = async () => {
    const { products } = this.state
    try {
      this.setState({ calculating: true })
      const ids = products.map(p => p._id)
      await expirationService.calculatePriceSuggestions(ids)
      this.fetchProducts()
      this.setState({ calculating: false })
      this.props.layout.showSnackbar(false, 'Preços recalculados')
    } catch (e) {
      this.setState({ calculating: false })
      this.fetchProducts()
    }
  }

  handleDownloadProducts = async () => {
    let { layout, filtered } = this.state

    filtered = filtered.map(f => f._id)

    if (filtered.length === 0) {
      return layout.showSnackbar(true, 'Nenhum produto para exportar')
    }

    try {
      const data = await expirationService.download(filtered, true)
      const date = new Date().toISOString().substring(0, 10)

      const element = document.createElement('a')
      const file = new Blob(data, { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = `produtos-analise-${date}.txt`
      element.click()
    } catch (e) {
      console.log(e)
    }
  }

  render({ expiration }) {
    const {
      products,
      filtered,
      flag,
      open,
      departments,
      selectedDepartments,
      calculating,
    } = this.state
    return (
      <Fragment>
        <Spacer double />
        <DepartmentsPanel
          selected={selectedDepartments}
          onClose={() => this.setState({ open: false })}
          onSelect={this.handleToggleDepartment}
          departments={departments}
          open={open}
        />
        <MaterialContainer maxWidth='lg'>
          <Paper elevation={0}>
            <Box padding={2}>
              <Grid container alignItems='center' justify='space-between'>
                <Grid item alignSelf='center'>
                  <IconButton
                    onClick={() => route(ApplicationRoutes.ExpirationBookmarks)}
                  >
                    <Badge
                      badgeContent={expiration.bookmarks.length}
                      color='secondary'
                    >
                      <Tooltip title='Exportação de Preços'>
                        <BookmarkBorderOutlined />
                      </Tooltip>
                    </Badge>
                  </IconButton>
                  <IconButton
                    onClick={() => route(ApplicationRoutes.ExpirationFinished)}
                  >
                    <Tooltip title='Finalizados antes do vencimento'>
                      <DoneAllOutlined />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={this.handleCalculatePriceSuggestions}>
                    <Tooltip title='Calcular sugestões de preço'>
                      <AttachMoneyIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={this.handleDownloadProducts}>
                    <Tooltip title='Baixar produtos'>
                      <GetAppOutlined />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => this.setState({ open: true })}>
                    <Tooltip title='Filtros'>
                      <FilterListOutlined
                        color={
                          selectedDepartments.length !== departments.length
                            ? 'secondary'
                            : 'inherit'
                        }
                      />
                    </Tooltip>
                  </IconButton>
                  {calculating && (
                    <IconButton>
                      <CircularProgress size={14} color='secondary' />
                    </IconButton>
                  )}
                </Grid>
                <Grid style={{ margin: 16 }} item>
                  <FlagPicker flag={flag} onSelect={this.handleFlagUpdate} />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </MaterialContainer>
        <Spacer />
        <Container padding={24}>
          {products && products.length == 0 && (
            <TableContainer
              elevation={0}
              style={{ padding: 24, textAlign: 'center' }}
              component={Paper}
            >
              <Typography variant='h6'>Nenhum produto para análise</Typography>
            </TableContainer>
          )}
          {products && products.length > 0 && (
            <Fragment>
              <ProductList
                products={filtered}
                onUpdate={async product => {
                  try {
                    product.status = 'verify'
                    await expirationService.update(product)
                    this.props.layout.showSnackbar(
                      false,
                      'Produto atualizado com sucesso',
                    )
                    this.fetchProducts()
                  } catch (e) {
                    this.props.layout.showSnackbar(
                      true,
                      'Erro ao atualizar o produto',
                    )
                  }
                }}
              />
              <Spacer double />
              <Spacer double />
            </Fragment>
          )}
        </Container>
      </Fragment>
    )
  }
}

export default inject('auth', 'layout', 'expiration')(observer(ExpirationProducts))
