import AuthStore from './auth/store'
import ExpirationStore from './expiration/store'
import LayoutStore from './layout/store'

class RootStore {
  constructor() {
    this.auth = new AuthStore(this)
    this.layout = new LayoutStore(this)
    this.expiration = new ExpirationStore(this)
  }
}

const store = new RootStore()
export default store