import { Container as MaterialContainer, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight + 64}px)`,
    display: 'flex',
  },
}))

export default function Container(props) {
  const classes = useStyles()
  const styles = {
    justifyContent: props.centerHorizontal ? 'center' : 'flex-start',
    alignItems: props.centerVertical ? 'center' : 'flex-start',
    padding: props.padding || 0,
  }
  return (
    <MaterialContainer maxWidth='lg' style={styles} className={classes.root}>
      {props.children}
    </MaterialContainer>
  )
}
