import ReportsIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import DashboardOutlined from '@material-ui/icons/DashboardOutlined'
import ApplicationRoutes from '../routes'

export default [
  {
    label: 'Validade',
    route: ApplicationRoutes.Expiration,
    icon: <DashboardOutlined />,
  },
  {
    label: 'Validade - Histórico',
    route: ApplicationRoutes.ExpirationHistory,
    icon: <DashboardOutlined />,
  },
  // {
  //   label: 'Relatórios',
  //   route: ApplicationRoutes.ExpirationReports,
  //   icon: <ReportsIcon />,
  // },
]
