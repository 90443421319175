import Alert from '@material-ui/lab/Alert'
import ApplicationRoutes from '../routes'
import Container from '../components/container/index'
import graphql from '../http/graphql'
import Spacer from '../components/spacer'
import { Component, Fragment } from 'preact'
import { inject, observer } from 'mobx-preact'
import { route } from 'preact-router'
import {
  Paper,
  TextField,
  Typography,
  withStyles,
  Button,
  Snackbar,
} from '@material-ui/core'

@inject('auth')
@observer
class LoginScreen extends Component {
  state = {
    email: '',
    password: '',
    error: false,
  }

  authenticate = async () => {
    const { email, password } = this.state
    try {
      const credentials = await graphql.authenticate({ email, password })

      if (credentials.token) {
        this.props.auth.setCredentials(credentials)

        localStorage.setItem('token', credentials.token)
        localStorage.setItem('user', JSON.stringify(credentials.user))

        route(ApplicationRoutes.Home)
      } else {
        this.setState({ error: true })
      }
    } catch (e) {
      console.log(e)
    }
  }

  handleChange = name => e => {
    this.setState({ [name]: e.target.value })
  }

  render({ classes }) {
    const { email, password, error } = this.state
    return (
      <Fragment>
        <Snackbar open={error} autoHideDuration={5000}>
          <Alert variant='filled' severity='error'>
            Ocorreu um erro ao tentar logar
          </Alert>
        </Snackbar>
        <Container
          centerVertical
          centerHorizontal
          className={classes.root}
          maxWidth='md'
        >
          <Paper elevation={1} className={classes.paper}>
            <Typography align='center' variant='h6'>
              BerryProfit
            </Typography>
            <Spacer double />
            <form>
              <TextField
                value={email}
                onChange={this.handleChange('email')}
                variant='outlined'
                label='Email'
                fullWidth
              />
              <Spacer />
              <TextField
                value={password}
                onChange={this.handleChange('password')}
                variant='outlined'
                label='Senha'
                type='password'
                fullWidth
              />
              <Spacer double />
              <Button
                onClick={this.authenticate}
                variant='contained'
                color='secondary'
                fullWidth
                size='large'
              >
                Continuar
              </Button>
            </form>
          </Paper>
        </Container>
      </Fragment>
    )
  }
}

const styles = () => ({
  paper: {
    width: '100%',
    maxWidth: 360,
    padding: 24,
  },
})

export default withStyles(styles)(LoginScreen)
