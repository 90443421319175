import { Grid, Typography } from '@material-ui/core'

export const flagLabels = {
  white: 'Volume',
  green: 'Agenda',
  orange: 'Etiqueta',
  red: 'Troca',
  null: 'Sem bandeira',
}

export default function LabeledFlag({ flag }) {
  return (
    <Grid container>
      <Grid style={{ marginRight: 8, verticalAlign: 'center' }} item>
        <img height={24} width={16} src={`/assets/images/flag_${flag}.png`} />
      </Grid>
      <Grid item>
        <Typography variant='caption'>{flagLabels[flag]}</Typography>
      </Grid>
    </Grid>
  )
}
