export const addExpirationCommentMutation = `
mutation AddExpirationComment($id: String!, $data: ExpirationCommentInput!) {
  addExpirationComment(id: $id, data: $data) {
    _id
    _key
    _rev
  }
}
`

export const updateExpirationMutation = `
mutation UpdateExpirationProduct($id: String, $data: ExpirationProductInput!) {
  updateExpirationProduct(id: $id, data: $data) {
    _id
    _key
    _rev
  }
}
`

export const verifyExpirationProductsMutation = `
mutation VerifyExpirationProducts($ids: [String]!) {
  verifyExpirationProducts(ids: $ids)
}
`

export const calculateExpirationPriceSuggestionsMutation = `
mutation CalculateExpirationPriceSuggestions($ids: [String]!) {
  calculateExpirationPriceSuggestions(ids: $ids)
}
`
