import ApplicationRoutes from '../../routes'
import Container from '../../components/container'
import expirationService from '../service'
import FlagPicker from '../common/flag_picker'
import Formatter from '../../utils/formatter'
import LabeledValue from '../../components/labeled'
import NumberFormat from 'react-number-format'
import Spacer from '../../components/spacer'
import { Component } from 'preact'
import { DatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import { route } from 'preact-router'
import {
  Grid,
  Paper,
  Typography,
  withStyles,
  TextField,
  Button,
} from '@material-ui/core'

class ExpirationProductEdit extends Component {
  state = {
    product: undefined,
  }

  componentDidMount() {
    this.fetchProduct(this.props.matches.key)
  }

  async fetchProduct(key) {
    try {
      const product = await expirationService.product(key)
      this.setState({ product })
    } catch (e) {
      console.log(e)
    }
  }

  handleDateChange = e => {
    const { product } = this.state
    product.expiration = e.toISODate()
    this.setState({ product })
  }

  handleFlagUpdate = flag => {
    const { product } = this.state
    product.flag = flag
    this.setState({ product })
  }

  handleSuggestionUpdate = e => {
    const { product } = this.state
    product.suggestedPrice = e.floatValue
    this.setState({ product })
  }

  handleAmountChange = e => {
    const { product } = this.state
    product.amount = e.floatValue
    this.setState({ product })
  }

  handleCodeUpdate = e => {
    const { product } = this.state
    product.secondaryBarcode = e.target.value
    this.setState({ product })
  }

  handleSaveProduct = async () => {
    const { product } = this.state
    try {
      await expirationService.update(product)
      route(ApplicationRoutes.Expiration)
    } catch (e) {
      console.log(e)
    }
  }

  render({ classes }) {
    const { product } = this.state
    return (
      <Container centerHorizontal>
        {product && (
          <Paper
            style={{ marginTop: 48 }}
            className={classes.root}
            elevation={0}
          >
            <Grid container direction='column'>
              <Grid item>
                <Typography variant='h6'>{product.description}</Typography>
              </Grid>
              <Grid item>
                {product.complement && (
                  <Typography variant='caption'>
                    {product.complement}
                  </Typography>
                )}
                {product.brand && (
                  <Typography variant='caption'> - {product.brand}</Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant='caption'>
                  {product.barcode} - {product.code}
                </Typography>
              </Grid>
              <Spacer double />
              <Grid container item justify='space-between'>
                <Grid item>
                  <LabeledValue
                    label='Venda média'
                    value={`${Formatter.number(product.average, 1)} un`}
                  />
                </Grid>
                <Grid item>
                  <LabeledValue
                    label='Venda Projetada'
                    value={`${Formatter.number(product.projected, 1)} un`}
                  />
                </Grid>
              </Grid>
              <Spacer double />
              <Grid item>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  variant='outlined'
                  label='Quantidade'
                  margin='normal'
                  value={product.amount}
                  decimalScale={2}
                  onValueChange={this.handleAmountChange}
                  thousandSeparator='.'
                  decimalSeparator=','
                  prefix={''}
                />
              </Grid>
              <Spacer />
              <Grid item>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  variant='outlined'
                  label='Preço'
                  margin='normal'
                  value={product.suggestedPrice}
                  decimalScale={2}
                  onValueChange={this.handleSuggestionUpdate}
                  thousandSeparator='.'
                  decimalSeparator=','
                  prefix={'R$ '}
                />
              </Grid>
              <Spacer double />
              <Grid item>
                <DatePicker
                  fullWidth
                  value={DateTime.fromISO(product.expiration)}
                  inputFormat='dd/MM/yyyy'
                  onChange={date => this.handleDateChange(date)}
                  renderInput={props => (
                    <TextField variant='outlined' {...props} />
                  )}
                />
              </Grid>
              <Spacer double />
              <Grid item>
                <TextField
                  variant='outlined'
                  label='Código de Barras'
                  onChange={this.handleCodeUpdate}
                  value={product.secondaryBarcode}
                />
              </Grid>
              <Spacer double />
              <Spacer double />
              <Grid item>
                <FlagPicker
                  flag={product.flag}
                  onSelect={this.handleFlagUpdate}
                />
              </Grid>
              <Spacer double />
              <Spacer double />
              <Button
                onClick={this.handleSaveProduct}
                variant='contained'
                color='secondary'
                fullWidth
              >
                Salvar Alterações
              </Button>
            </Grid>
          </Paper>
        )}
      </Container>
    )
  }
}

const styles = () => ({
  root: {
    padding: 32,
  },
})

export default withStyles(styles)(ExpirationProductEdit)
