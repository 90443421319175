import Container from '../../components/container'
import expirationService from '../service'
import ListDate from '../../components/list_date'
import { CloseOutlined, SendOutlined } from '@material-ui/icons'
import { DateTime } from 'luxon'
import { Fragment } from 'preact'
import { inject } from 'mobx-preact'
import { timeAgo } from '../../utils/date'
import { useEffect, useState } from 'preact/hooks'
import {
  withStyles,
  Typography,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Grid,
  Divider,
  TextField,
} from '@material-ui/core'

const CommentsPanel = ({ classes, opened, onClose, id, auth }) => {
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])

  useEffect(() => {
    if (!id) {
      return
    }
    const getComments = async () => {
      const comments = await expirationService.comments(id)
      setComments(comments)
    }

    getComments()
  }, [id])

  const addComment = async () => {
    const commentObj = {
      comment,
      createdAt: DateTime.local().toUTC().toISO(),
      expiration: id,
      user: auth.user.name,
    }
    await expirationService.addComment(id, commentObj)
    setComment('')
    setComments([commentObj, ...comments])
  }

  return (
    <Drawer anchor='right' open={opened} onClose={onClose}>
      <Toolbar className={classes.root} position='static'>
        <Typography variant='h5'>Comentários</Typography>
        <div style='flex:1' />
        <IconButton onClick={onClose} edge='end'>
          <CloseOutlined />
        </IconButton>
      </Toolbar>
      <List>
        {comments && comments.length == 0 && (
          <Container centerVertical centerHorizontal>
            <Typography variant='subtitle1'>
              Adicione o primeiro comentário
            </Typography>
          </Container>
        )}
        {comments &&
          comments.map(c => (
            <Fragment>
              <ListItem>
                <Grid container alignContent='center'>
                  <Grid item xs={3}>
                    <ListDate date={c.createdAt} />
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography variant='h6'>{c.user}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='caption'>
                          {timeAgo(c.createdAt)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body1'>{c.comment}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
      </List>
      <Grid container className={classes.addComment}>
        <Grid item xs={10}>
          <TextField
            onChange={e => {
              setComment(e.target.value)
            }}
            autoFocus={true}
            value={comment}
            placeholder='Digite o seu comentário'
            fullWidth
            variant='outlined'
          />
        </Grid>
        <Grid container justify='flex-end' item xs={2}>
          <IconButton onClick={addComment}>
            <SendOutlined color='secondary' />
          </IconButton>
        </Grid>
      </Grid>
    </Drawer>
  )
}

const styles = theme => ({
  root: {
    width: 480,
  },
  addComment: {
    position: 'fixed',
    width: 480,
    bottom: 0,
    padding: 16,
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#fff',
  },
})

const CommentsPanelStyled = withStyles(styles)(CommentsPanel)
export default inject('auth')(CommentsPanelStyled)
