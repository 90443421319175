import ApplicationRoutes from '../../routes'
import ArrowBack from '@material-ui/icons/ArrowBackOutlined'
import ConfirmDialog from '../../components/confirm/index'
import Container from '../../components/container'
import expirationService from '../service'
import ProductList from '../common/products'
import Spacer from '../../components/spacer/index'
import { Component } from 'preact'
import { inject, observer } from 'mobx-preact'
import { route } from 'preact-router'
import {
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core'

class ExpirationFinished extends Component {
  state = {
    products: null,
  }

  componentDidMount() {
    this.fetchProducts()
  }

  async fetchProducts() {
    try {
      const products = await expirationService.finishedBeforeExpiration()
      this.setState({ products })
    } catch (e) {
      console.log(e)
    }
  }

  verifyProducts = async () => {
    const { products } = this.state
    const { layout, expiration } = this.props

    if (products.length === 0) {
      return layout.showSnackbar(true, 'Nenhum produto para alterar status')
    }

    try {
      if (expiration.bookmarkIds) {
        await expirationService.verifyProducts(expiration.bookmarkIds)
        layout.showSnackbar(
          false,
          'Atualizado status dos produtos para Verificar',
        )
        expiration.clearBookmarks()
        history.go(-1)
      }
    } catch (e) {
      console.log(e)
    }
  }

  handleUpdateStatus = async product => {
    if (!product) {
      return
    }

    product.finishedAndValidated = true
    await expirationService.update(product)

    this.props.layout.showSnackbar(false, 'Produto atualizado com sucesso')
    this.fetchProducts()
  }

  render({ classes }) {
    const { products, confirm } = this.state
    return (
      <Container>
        <ConfirmDialog
          open={confirm}
          title='Alterar status'
          content='Deseja alterar o status dos produtos para Verificar? Não é possível desfazer esta operação.'
          cancelLabel='Cancelar'
          confirmLabel='Alterar'
          onCancel={() => this.setState({ confirm: false })}
          onConfirm={() => {
            this.verifyProducts()
            this.setState({ confirm: false })
          }}
        />
        <Grid style={{ marginTop: 24 }} container direction='column'>
          <Grid item>
            <Toolbar>
              <IconButton
                onClick={() => route(ApplicationRoutes.Expiration)}
                edge='start'
                color='inherit'
              >
                <ArrowBack />
              </IconButton>
              <div style={{ flex: 1 }} />
              <Typography variant='h6'>
                Finalizados antes do vencimento
              </Typography>
              <div style={{ flex: 1 }} />
            </Toolbar>
          </Grid>
          <Spacer double />
          {products && products.length === 0 && (
            <Grid item>
              <Paper elevation={0} className={classes.emptyMessage}>
                <Typography>
                  Nenhum produto finalizado antes do vencimento
                </Typography>
              </Paper>
            </Grid>
          )}
          {products && products.length > 0 && (
            <ProductList
              products={products}
              onUpdate={this.handleUpdateStatus}
            />
          )}
        </Grid>
      </Container>
    )
  }
}

const styles = () => ({
  emptyMessage: {
    padding: 24,
    textAlign: 'center',
  },
})

const ExpirationFinishedScreen = inject(
  'expiration',
  'layout',
)(observer(ExpirationFinished))
export default withStyles(styles)(ExpirationFinishedScreen)
