import { Fragment } from 'preact'
import { Grid, Tooltip } from '@material-ui/core'
import { flagLabels } from './flag'

const flags = ['white', 'green', 'orange', 'red']

const useStyles = () => ({
  icon: {
    margin: 24,
  },
})

export default function FlagPicker({ flag, onSelect }) {
  const classes = useStyles()
  return (
    <Fragment>
      <Grid container spacing={8}>
        {flags.map(f => (
          <Grid onClick={() => onSelect(f)} className={classes.icon} item>
            <Tooltip title={flagLabels[f]}>
              <img
                style={{ cursor: 'pointer', opacity: flag === f ? 1.0 : 0.3 }}
                height={24}
                width={16}
                src={`/assets/images/flag_${f}.png`}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}
