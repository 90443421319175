import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core'
import Formatter from '../../utils/formatter'

export function ReportDepartmentsTable ({ data }) {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Departamento</TableCell>
            <TableCell>Eventos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map(d => (
              <TableRow>
                <TableCell>{d.department}</TableCell>
                <TableCell>{Formatter.number(d.total)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
