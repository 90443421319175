import App from './app'
import ApplicationRoutes from './routes'
import LoginScreen from './login/screen'
import LuxonUtils from '@material-ui/pickers/adapter/luxon'
import ProtectedRoute from './protected'
import Router from 'preact-router'
import store from './store'
import theme from './theme'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { LocalizationProvider } from '@material-ui/pickers'
import { Provider } from 'mobx-preact'

export default function Index() {
  return (
    <Provider {...store}>
      {''}
      <LocalizationProvider dateAdapter={LuxonUtils}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <ProtectedRoute path={'/:*?'} component={App} />
            <LoginScreen path={ApplicationRoutes.Login} />
          </Router>
        </MuiThemeProvider>
      </LocalizationProvider>
    </Provider>
  )
}
