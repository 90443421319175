import Format from './format'

class Formatter {
  static currency = (value, maxDigits = 0, symbol = null) => {
    if (!value) return 'R$ 0,00'
    value = value || 0.0
    return Format.formatMoney(value, symbol, maxDigits)
  }

  static number = (value, maxDigits = 0) => {
    if (!value) return 0
    value = value || 0.0
    return Format.formatNumber(value, maxDigits)
  }

  static percent = (value, maxDigits = 1) => {
    if (!value) return '0%'
    value = value || 0.0
    value = Format.formatNumber(value * 100, maxDigits)
    return `${value}%`
  }
}

export default Formatter
