import { Grid, Box, Paper, Typography } from '@material-ui/core'
import Spacer from '../../components/spacer'
import { Fragment } from 'preact'

export function ReportCard ({ label, value, children }) {
  return (
    <Paper elevation={0}>
      <Box padding={4}>
        <Grid container direction='column'>
          <Grid item>
            <Typography style={{ fontFamily: 'GoogleSans', opacity: 0.89 }}>
              {label}
            </Typography>
          </Grid>
          <Spacer />
          <Grid item>
            <Typography
              style={{
                fontFamily: 'GoogleSans',
                fontWeight: 300,
                fontSize: 38,
              }}
            >
              {value}
            </Typography>
          </Grid>
          {children && (
            <Fragment>
              <Spacer double />
              <Grid item>{children}</Grid>
            </Fragment>
          )}
        </Grid>
      </Box>
    </Paper>
  )
}
