export const expirationOverviewQuery = `
  query ExpirationOverview($store: String!) {
    expirationOverview(store: $store) {
      status
      amount
      display
      products
      total
    }
  }
`

export const expirationProductsByIdQuery = `
query ExpirationProductsById($ids: [String]!) {
  expirationProductsById(ids: $ids) {
    _id
    _key
    _rev
    amount
    average
    barcode
    brand
    code
    complement
    date
    dateLimit
    days
    department
    departmentDescription
    description
    expiration
    expired
    flag
    group
    groupDescription
    loss
    projected
    sales
    secondaryBarcode
    status
    store
    suggestedPrice
    price
    averageCost
  }
}
`

export const expirationProductQuery = `
query ExpirationProduct($id: String!) {
  expirationProduct(id: $id) {
    _id
    _key
    _rev
    amount
    average
    barcode
    brand
    code
    complement
    days
    departmentDescription
    description
    expiration
    flag
    groupDescription
    numComments
    projected
    secondaryBarcode
    status
    store
    suggestedPrice
    price
    averageCost
  }
}
`

export const expirationProducts = `
query ExpirationProducts($filter: ExpirationProductsFilter!) {
  expirationProducts(filter: $filter) {
    _id
    _key
    _rev
    amount
    average
    barcode
    brand
    code
    complement
    departmentDescription
    description
    expiration
    flag
    groupDescription
    numComments
    projected
    secondaryBarcode
    status
    store
    suggestedPrice
    price
    averageCost
  }
}
`

export const expirationProductComments = `
query ExpirationComments($id: String!) {
  expirationComments(id: $id) {
    user
   comment
   createdAt
  }
}
`

export const expirationProductHistory = `
query ExpirationHistory($id: String!) {
  expirationHistory(id: $id) {
    user
    details
    createdAt
  }
}
`

export const expirationDownloadProductsMutation = `
mutation DownloadExpirationProducts($ids: [String]!, $exp: Boolean) {
  downloadExpirationProducts(ids: $ids, exp: $exp)
}
`

export const finishedBeforeExpirationQuery = `
query ExpirationFinishedBeforeExpiration {
  expirationFinishedBeforeExpiration {
    _id
    _key
    _rev
    amount
    average
    barcode
    brand
    code
    complement
    departmentDescription
    description
    expiration
    flag
    groupDescription
    numComments
    projected
    secondaryBarcode
    status
    store
    suggestedPrice
  }
}
`
