import expirationService from '../service'
import ListDate from '../../components/list_date'
import { CloseOutlined } from '@material-ui/icons'
import { Fragment } from 'preact'
import { timeAgo } from '../../utils/date'
import { useEffect, useState } from 'preact/hooks'
import {
  withStyles,
  Typography,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Grid,
  Divider,
} from '@material-ui/core'
import Container from '../../components/container'

const HistoryPanel = ({ classes, opened, onClose, id }) => {
  const [history, setHistory] = useState([])

  useEffect(() => {
    if (!id) {
      return
    }
    const getHistory = async () => {
      const comments = await expirationService.history(id)
      setHistory(comments)
    }
    getHistory()
  }, [id])

  return (
    <Drawer anchor='right' open={opened} onClose={onClose}>
      <Toolbar className={classes.root} position='static'>
        <Typography variant='h6'>Histórico</Typography>
        <div style='flex:1' />
        <IconButton onClick={onClose} edge='end'>
          <CloseOutlined />
        </IconButton>
      </Toolbar>
      <List>
        {history && history.length == 0 && (
          <Container centerVertical centerHorizontal>
            <Typography variant='subtitle1'>
              Nenhum histórico para este produto
            </Typography>
          </Container>
        )}
        {history &&
          history.map(c => (
            <Fragment>
              <ListItem>
                <Grid container alignContent='center'>
                  <Grid item xs={3}>
                    <ListDate date={c.createdAt} />
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography variant='h6'>{c.user}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='caption'>
                          {timeAgo(c.createdAt)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body1'>{c.details}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
      </List>
    </Drawer>
  )
}

const styles = () => ({
  root: {
    width: 480,
  },
})

export default withStyles(styles)(HistoryPanel)
