import Container from '../../components/container'
import expirationService from '../service'
import ProductList from '../common/products'
import { Component } from 'preact'
import { inject, observer } from 'mobx-preact'
import { Paper, Typography, Grid } from '@material-ui/core'

class ExpirationHistory extends Component {
  state = {
    products: undefined,
  }

  componentDidMount = () => {
    this.fetchProducts()
  }

  fetchProducts = async () => {
    try {
      const products = await expirationService.products({ status: 'verify' })
      this.setState({ products })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { products } = this.state
    return (
      <Container padding={24}>
        <Grid direction='column' container>
          <Grid item>
            <Paper style={{ padding: 24, marginBottom: 24 }} elevation={0}>
              <Typography variant='subtitle1'>Validade - Histórico</Typography>
            </Paper>
          </Grid>
          <Grid item>
            <ProductList
              onUpdate={async () => {
                this.props.layout.showSnackbar(
                  true,
                  'Não é possível alterar o status',
                )
              }}
              products={products}
            />
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default inject('layout')(observer(ExpirationHistory))
