export const expirationStatsQuery = `
query ExpirationStats($filter: ExpirationProductsFilter!){
  expirationStats(filter: $filter) {
    display
    date
    store
    status
    amount
    products
    loss
  }
}
`

export const expirationEventStatsQuery = `
query ExpirationEventStats($filter: ExpirationProductsFilter!){
  expirationEventStats(filter: $filter) {
    display
    event
    products
    amount
    loss
  }
}
`
