import { createMuiTheme } from '@material-ui/core/styles'
import './index.css'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#3d4977',
      contrastText: '#fff',
    },
    background: {
      default: '#f5f5f5',
    },
    secondary: {
      main: '#f44336',
    },
  },
  typography: {
    h5: {
      fontFamily: 'GoogleSans',
      opacity: 0.89
    },
  },
})
