import { DateTime, Settings } from 'luxon'
Settings.defaultLocale = 'pt-BR'

const dateIntervals = [
  {
    label: 'Hoje',
    start: DateTime.local().toISODate(),
    end: DateTime.local().toISODate(),
  },
  {
    label: 'Ontem',
    start: DateTime.local().minus({ days: 1 }).toISODate(),
    end: DateTime.local().minus({ days: 1 }).toISODate(),
  },
  {
    label: 'Últimos 7 dias',
    start: DateTime.local().minus({ days: 7 }).toISODate(),
    end: DateTime.local().toISODate(),
  },
  {
    label: 'Últimos 14 dias',
    start: DateTime.local().minus({ days: 14 }).toISODate(),
    end: DateTime.local().toISODate(),
  },
  {
    label: 'Últimos 30 dias',
    start: DateTime.local().minus({ days: 30 }).toISODate(),
    end: DateTime.local().toISODate(),
  },
  {
    label: 'Semana atual',
    start: DateTime.local().startOf('week').toISODate(),
    end: DateTime.local().toISODate(),
  },
  {
    label: 'Mês Atual',
    start: DateTime.local().startOf('month').toISODate(),
    end: DateTime.local().toISODate(),
  },
  {
    label: 'Mês Anterior',
    start: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
    end: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
  },
  {
    label: 'Este Ano',
    start: DateTime.local().startOf('year').toISODate(),
    end: DateTime.local().toISODate(),
  },
]

export default dateIntervals
