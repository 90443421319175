import ArrowBack from '@material-ui/icons/ArrowBackOutlined'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import { inject, observer } from 'mobx-preact'
import {
  IconButton,
  makeStyles,
  Toolbar,
  AppBar as MaterialAppBar,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

function AppBar({ layout }) {
  const classes = useStyles()
  return (
    <MaterialAppBar elevation={1} position='static'>
      <Toolbar>
        {layout.backButton ? (
          <IconButton
            onClick={() => history.go(-1)}
            className={classes.menuButton}
            color='inherit'
          >
            <ArrowBack />
          </IconButton>
        ) : (
          <IconButton
            onClick={layout.toggleDrawer}
            className={classes.menuButton}
            color='inherit'
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography variant='h6' className={classes.title}>
          BerryProfit
        </Typography>
        <div width='100%' />
        <IconButton id='help-icon'>
          <HelpIcon style={{ color: '#fff' }} />
        </IconButton>
      </Toolbar>
    </MaterialAppBar>
  )
}

export default inject('layout', 'auth')(observer(AppBar))
