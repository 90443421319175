import { Grid, Paper, Box, Typography } from '@material-ui/core'

export function SectionTitle ({ title }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Box padding={2}>
            <Typography align='center' variant='h5'>
              {title}
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}
