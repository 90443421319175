import { Grid, Typography } from '@material-ui/core'

export default function LabeledValue({ value, label }) {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography variant='caption'>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant='h6'>{value}</Typography>
      </Grid>
    </Grid>
  )
}
