import CheckIcon from '@material-ui/icons/CheckOutlined'
import { CloseOutlined } from '@material-ui/icons'
import { inject } from 'mobx-preact'
import {
  withStyles,
  Typography,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'

const DepartmentsPanel = ({
  classes,
  open,
  onClose,
  departments,
  selected,
  onSelect,
}) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Toolbar className={classes.root} position='static'>
        <Typography variant='h5'>Departamentos</Typography>
        <div style='flex:1' />
        <IconButton onClick={onClose} edge='end'>
          <CloseOutlined />
        </IconButton>
      </Toolbar>
      <List component='nav'>
        {departments &&
          departments.map(d => (
            <ListItem button style={{ padding: 16 }} onClick={() => onSelect(d)}>
              <ListItemText>{d}</ListItemText>
              <ListItemSecondaryAction>
                <CheckIcon
                  color={selected.includes(d) ? 'secondary' : 'inherit'}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Drawer>
  )
}

const styles = theme => ({
  root: {
    width: 480,
  },
  addComment: {
    position: 'fixed',
    width: 480,
    bottom: 0,
    padding: 16,
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#fff',
  },
})

const DepartmentsPanelStyled = withStyles(styles)(DepartmentsPanel)
export default inject('auth')(DepartmentsPanelStyled)
