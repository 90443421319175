import SnackbarAlert from './alert'
import SnackbarSuccess from './success'
import { Fragment } from 'preact'
import { inject, observer } from 'mobx-preact'

function SnackbarController(props) {
  return (
    <Fragment>
      <SnackbarSuccess
        open={props.layout.snackbarOpen && !props.layout.snackbarAlert}
        onClose={props.layout.hideSnackbar}
        message={props.layout.snackbarMessage}
      />
      <SnackbarAlert
        open={props.layout.snackbarOpen && props.layout.snackbarAlert}
        onClose={props.layout.hideSnackbar}
        message={props.layout.snackbarMessage}
      />
    </Fragment>
  )
}

export default inject('layout')(observer(SnackbarController))
