import ExitApp from '@material-ui/icons/ExitToApp'
import menu from './menu'
import Spacer from '../components/spacer'
import { inject, observer } from 'mobx-preact'
import { route } from 'preact-router'
import {
  Drawer as MaterialDrawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ListItemAvatar,
  Grid,
  Divider,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  list: {
    width: 300,
  },
}))

function Drawer({ layout, auth }) {
  const classes = useStyles()
  return (
    <MaterialDrawer
      anchor={'left'}
      open={layout.drawerOpened}
      onClose={layout.toggleDrawer}
    >
      <Grid container direction='column'>
        <List>
          <ListItem>
            <ListItemText
              primary={auth.user.name}
              secondary={auth.user.email}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={auth.logout}>
                <ExitApp />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Divider />
      </Grid>
      <Spacer />
      <List className={classes.list}>
        {menu.map(i => (
          <ListItem
            button
            onClick={() => {
              layout.toggleDrawer()
              route(i.route)
            }}
          >
            <ListItemAvatar>{i.icon}</ListItemAvatar>
            <ListItemText>{i.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </MaterialDrawer>
  )
}

export default inject('layout', 'auth')(observer(Drawer))
